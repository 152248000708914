import { ScreenSize } from 'glints-aries/lib/Utils/StyleConfig';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    box-sizing: border-box;
  }
`;

export const GallerySection = styled.div`
  margin-top: 10px;
  width: 100%;

  @media (max-width: ${ScreenSize.mobileL}px) {
    width: 100%;

    [class*='GalleryStyle__GalleryItemWrapper'] {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
      width: 100%
      box-sizing: border-box;
    }

    [class*='GalleryStyle__GalleryItem'] {
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      margin: 0px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

export const CustomGalleryImageWrapper = styled.div`
  padding: 0;
  position: relative;

  img {
    width: 250px;
    height: auto;
    aspect-ratio: 1 / 1;

    @media (max-width: ${ScreenSize.desktopS}px) {
      width: 230px;
      height: auto;
    }
  }
`;
