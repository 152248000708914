import React from 'react';
import { WarningSolidIcon } from 'glints-aries';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import * as Styled from './styles.sc';

const Retry = ({ intl, onRetry }) => {
  return (
    <Styled.Wrapper>
      <WarningSolidIcon />
      <Styled.ErrorText>{intl.formatMessage(messages.error)}</Styled.ErrorText>
      <Styled.Button onClick={onRetry}>
        <Styled.RefreshIcon />
        {intl.formatMessage(messages.retry)}
      </Styled.Button>
    </Styled.Wrapper>
  );
};

const messages = defineMessages({
  error: {
    id: 'something.went.wrong',
    defaultMessage: 'Oops, something went wrong!',
  },
  retry: {
    id: 'try.again',
    defaultMessage: 'Try Again',
  },
});

Retry.propTypes = {
  intl: PropTypes.object.isRequired,
  onRetry: PropTypes.func,
};

export default injectIntl(Retry);
