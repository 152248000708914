import { Greyscale, RefreshSolidIcon } from 'glints-aries';
import styled from 'styled-components';

import UnstyledButton from 'src/components/Button/UnstyledButton';

export const Wrapper = styled.div`
  color: #757575;

  > svg {
    width: 60px;
    height: 60px;
    fill: #757575;
  }
`;

export const ErrorText = styled.h3`
  font-size: 18px;
`;

export const Button = styled(UnstyledButton)`
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #fbbd08;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    background-color: #eaae00;
  }
  &:active {
    background-color: #cd9903;
  }
`;

export const RefreshIcon = styled(RefreshSolidIcon).attrs({
  color: Greyscale.white,
  width: '15px',
})`
  margin-right: 5px;
`;
