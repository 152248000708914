import React from 'react';
import Loadable from '@loadable/component';
import PropTypes from 'prop-types';

import Retry from 'src/components/Retry';

class LoaderErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <Retry onRetry={() => location.reload()} />;
    } else {
      return this.props.children;
    }
  }
}

export function loadable(moduleLoader, exportName = 'default', options = {}) {
  const Component = Loadable(async () => {
    const module = await moduleLoader();
    return module[exportName];
  }, options);

  const Loader = props => (
    <LoaderErrorBoundary>
      <Component {...props} />
    </LoaderErrorBoundary>
  );

  return Loader;
}

export default loadable;
