import React, { useEffect, useState } from 'react';
import { Gallery as AriesGallery, ScreenSize } from 'glints-aries';
import { Size } from 'glints-aries/lib/Display/Gallery/Gallery';

import loadable from 'src/common/GlintsLoadable';
import { regularScreenSizes } from 'src/common/hooks/useScreenChecking';
import { AssetType } from 'src/components/GlintsPicture/interfaces';

import * as Styled from './Gallery.sc';

const GlintsPicture = loadable(() => import('../GlintsPicture'));

export type Props = {
  photos: Array<string>;
  renderEmptyGallery: () => JSX.Element;
  header?: { altText?: string; assetType?: AssetType };
  size?: Size;
  showHeader?: boolean;
  currentScreen?: string;
};

const Gallery: React.FC<React.PropsWithChildren<Props>> = ({
  photos = [],
  renderEmptyGallery,
  header,
  size,
  showHeader = true,
  currentScreen,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  }, []);

  if (!photos || photos.length === 0) {
    return renderEmptyGallery();
  }

  const slidesToShowInNav = getSlidesToShowInNav(currentScreen);
  const selectedPhoto = showHeader ? photos.slice(1, photos.length) : photos;
  return (
    <div data-testid="gallery-container">
      <If condition={showHeader}>
        <Styled.HeaderWrapper data-testid="gallery-header">
          <GlintsPicture
            name={photos[0]}
            alt={header?.altText || 'First picture in the gallery'}
            assetType={header?.assetType || AssetType.images}
            sizes={`(min-width: ${ScreenSize.desktopS}px) 290px, 100vw`}
          />
        </Styled.HeaderWrapper>
      </If>
      <If condition={!isLoading}>
        <Styled.GallerySection>
          <AriesGallery imagesDisplayed={slidesToShowInNav} imageSize={size}>
            {selectedPhoto.map((photo: string) => (
              <Styled.CustomGalleryImageWrapper key={photo}>
                <Styled.CustomGalleryImageWrapper>
                  <GlintsPicture
                    name={photo}
                    alt="Company Gallery"
                    assetType={AssetType.companyPhotos}
                    sizes={`(min-width: ${ScreenSize.desktopS}px) 400px, 100vw`}
                  />
                </Styled.CustomGalleryImageWrapper>
              </Styled.CustomGalleryImageWrapper>
            ))}
          </AriesGallery>
        </Styled.GallerySection>
      </If>
    </div>
  );
};

export default Gallery;

const getSlidesToShowInNav = (screenSize?: string): number => {
  switch (screenSize) {
    case regularScreenSizes[2]:
      return 2;
    case regularScreenSizes[1]:
      return 3;
    case regularScreenSizes[0]:
    default:
      return 5;
  }
};
